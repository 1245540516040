(function ($) {
  $(document).ready(function () {

    /*
    if ($(window).width() > 1025) {
      var scroll = $(window).scrollTop()

      $('.layer').each(function () {
        var layer = $(this)

        var dataSpeed = layer.data('parallax-speed')

        var offsetY = -(scroll * dataSpeed)

        var translate = 'translate3d(0, ' + offsetY + 'px, 0)'

        layer.css('-webkit-transform', translate)

        layer.css('-moz-transform', translate)

        layer.css('-ms-transform', translate)

        layer.css('-o-transform', translate)

        layer.css('transform', translate)
      })

      $('.card').each(function () {
        var layer = $(this)

        var dataSpeed = layer.data('parallax-speed')

        var offsetY = -(scroll / 2 * dataSpeed) + 200

        var rotateSpeed = layer.data('rotate-speed')

        var rotate = -((scroll / 2 - 500) * rotateSpeed)

        var translate = 'rotate(' + rotate + 'deg)'

        layer.css({ '-webkit-transform': translate, top: offsetY })

        layer.css('-moz-transform', translate)

        layer.css('-ms-transform', translate)

        layer.css('-o-transform', translate)

        layer.css('transform', translate)
      })

      $(window).scroll(function () {
        var scroll = $(window).scrollTop()

        $('.layer').each(function () {
          var layer = $(this)

          var dataSpeed = layer.data('parallax-speed')

          var offsetY = -(scroll * dataSpeed)

          var translate = 'translate3d(0, ' + offsetY + 'px, 0)'

          layer.css('-webkit-transform', translate)

          layer.css('-moz-transform', translate)

          layer.css('-ms-transform', translate)

          layer.css('-o-transform', translate)

          layer.css('transform', translate)
        })

        $('.card').each(function () {
          var layer = $(this)

          var dataSpeed = layer.data('parallax-speed')

          var offsetY = -(scroll / 2 * dataSpeed) + 200

          var rotateSpeed = layer.data('rotate-speed')

          var rotate = -((scroll / 2 - 500) * rotateSpeed)

          var translate = 'rotate(' + rotate + 'deg)'

          layer.css({ '-webkit-transform': translate, top: offsetY })

          layer.css('-moz-transform', translate)

          layer.css('-ms-transform', translate)

          layer.css('-o-transform', translate)

          layer.css('transform', translate)
        })
      })
    } */
  })
}(jQuery))
